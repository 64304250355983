.player-wrapper {
    position: relative;
   /*  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-confirm-alert-body{
      width: auto !important;
  }

  .react-confirm-alert{
    padding: 0% 5%;
  }


  .react-confirm-alert-button-group > button:nth-child(2){
    background: #0d6efd !important;
    border-color: #0d6efd !important;
  }

.percent{font-family: 'Roboto Mono', sans-serif; font-weight: 600; font-size: 80px;}
.percent span{font-family: 'Roboto Mono', sans-serif; font-weight: 400; font-size: 50px; vertical-align: 11px; margin-left: 6px;}
  