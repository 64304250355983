
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@400;500&display=swap');

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css");

  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }
  
.login-container{
  overflow-x: hidden;
  position: relative;
}

.hidden{
  margin-right: -600px !important;
}

.showContainer{
  /* margin-right: 0px !important; */
  -webkit-transition: all 0.5s ease-out;
  transition-delay: 0.2s;
}

.container{
  position: relative;
}

.btn-back{
  /* position: relative !important; */
  top: -130px !important;
  left: 7% !important;
}

.btn-cancel{
    border-color: #212a53 !important;
    color: #212a53 !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    /* letter-spacing: 0.15em ; */
    background-color: none;
}

.btn-cancel:hover{
    color: white !important;
    background-color:#212a53 !important;
}

.btn-next{
    background-color: #212a53 !important;
    color: white !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    /* letter-spacing: 0.15em !important;  */
}


.login-wrapper .login-container {
  max-width: 500px;
  width: 100vw;
  display: block;
  position: relative;
  float: right;
  height: 100%;
}


html , main{
  height: 100vh;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  direction: ltr;
  font-family: 'Roboto', sans-serif;
}

.hide{display: none;}

.formDisabled{
  display: none;
}

.back{position: absolute; top: 10px; left: 10px;}

i.bi.bi-chevron-left{
  color:#212a53;
}

.bi.bi-file-earmark{
  display: inline-flex;
  align-self: center;
  padding: 0rem;
  position: relative;
}

.bi.bi-file-earmark::before{
  padding-right: 0.4rem;
}

.progress-bar{
  background-color: #eb3096 !important;
}

.progress-value{
  position:absolute;
  left:50%;
  transform: translate(-50%, 0%);
  color: black;
  font-weight: 800;
}

h1{font-size:28px; font-family: 'Anton', sans-serif; color:#eb3c96}
p{font-size:13px;} 

.iOSdescription{
  color:#eb3c96;
}



@media only screen 
  and (max-device-width: 640px) {
    
  .showContainer{
    -webkit-transition: all 0.5s ease-out;
    opacity: 1;
    transition-delay: 0.2s;
  }

  .hidden{-webkit-transition: all 0.5s ease-out;  opacity: 0;}

  .login-wrapper .login-container {
      margin: auto;
      min-height: 550px;
      height: auto;
      overflow: hidden;
      margin-top: 10%;
      width: 80%;
      float: none;
      border-radius: 5px;
  }

  .back{position: absolute; top: 10px; left: 0% !important;}

  .login-wrapper{
    background-size:auto;
  }
}
