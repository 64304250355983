
#not-found{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    background-color: white;
    text-align: center;
}

#not-found-container{
    font-size: 6rem !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#not-found-container h1{
    font-size: 6rem !important;
}

#not-found h3{
    font-size: 1.8rem !important;
    font-family: 'Roboto', sans-serif;
    color: rgba(102, 102, 102, 0.8);
    font-weight: normal;
}

#not-found a{
    font-size: 1rem;
    text-transform: uppercase;;
    top: 90%;
    position: relative;
    color: #212a53;
    text-decoration: none !important;
}


@media only screen 
  and (max-device-width: 640px) {
    #not-found{
        width: 80%;
    }
}
