
body .open-walkthrough {
    border: 0;
    background: #eb3c96;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 12px;
    height: 40px;
    width: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -60px;
}
  body .walkthrough {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
    background: linear-gradient(to right bottom, #fff, #fff);
    border-radius: 0;
    display: none;
    flex-direction: column;
    flex: 0 0 auto;
    font-size: 14px;
    height: 500px;
    overflow: hidden;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 280px;
    z-index: 1000;
  }

  .walkthrough h3{color: #eb3c96;}

  body .walkthrough.show {
    display: flex;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-100px);
}
body .walkthrough.reveal {
    opacity: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    -webkit-transition: all 0.5s ease-out;
}
  body .walkthrough .walkthrough-body {
    align-items: center;
    display: flex;
    flex: 1;
    text-align: center;
  }
  body .walkthrough .walkthrough-body .prev-screen,
  body .walkthrough .walkthrough-body .next-screen {
    align-self: stretch;
    background: none;
    border: 0;
    margin-top: 40px;
    color: #cccccc;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 24px;
    opacity: 1;
    outline: none;
    padding: 16px;
    transform: scale(1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 1000;
  }
  body .walkthrough .walkthrough-body  body .walkthrough .walkthrough-body .prev-screen:active,
  body .walkthrough .walkthrough-body .next-screen:active {
    color: white;
    transform-origin: center;
    transform: scale(1.25);
  }
  body .walkthrough .walkthrough-body .prev-screen:disabled,
  body .walkthrough .walkthrough-body .next-screen:disabled {
    opacity: 0;
  }
  body .walkthrough .walkthrough-body .prev-screen {
    order: 1;
  }
  body .walkthrough .walkthrough-body .next-screen {
    order: 3;
  }
  body .walkthrough .walkthrough-body .screens {
    flex: 1;
    align-self: stretch;
    position: relative;
    margin: 0 -16px;
    padding: 0;
    order: 2;
  }
  body .walkthrough .walkthrough-body .screens .screen {
    position: absolute;
    list-style-type: none;
  }
  body .walkthrough .walkthrough-body .media {
    background: rgba(250, 209, 247, 0.25);
    border-radius: 132px;
    height: 132px;
    margin: 32px auto;
    width: 132px;
  }
  body .walkthrough .walkthrough-body h3 {
    font-size: 15px;
    line-height: 1.4em;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
  body .walkthrough .walkthrough-body p {
    line-height: 1.6em;
    font-size: 13px;
    margin-top: 16px;
    padding-top: 0;
    color: rgba(102, 102, 102, 0.8);
  }
  body .walkthrough .walkthrough-pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  body .walkthrough .walkthrough-pagination .dot {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 8px;
    margin: 0 4px;
    transform: scale(0.75);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 8px;
  }
  body .walkthrough .walkthrough-pagination .dot.active {
    background: #eb3c96;
    transform: scale(1);
    transition-delay: 0.4s;
  }
  body .walkthrough .walkthrough-footer {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-around;
    padding: 0;
  }
  body .walkthrough .walkthrough-footer button {
    height: 40px;
    border: 0;
    background: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    border-radius: 0;
    color: #212a53;
    flex: 1;
    font-size: 12px;
    margin: 0;
    outline: 0;
    padding: 12px;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    cursor: pointer;
  }
  body .walkthrough .walkthrough-footer button:hover {
    background: #212a53;
    color: white;
  }
  body .walkthrough .walkthrough-footer button:active {
    background: #212a53;
  }
  body .walkthrough .walkthrough-footer button:disabled {
    cursor: pointer;
  }
  body .walkthrough .walkthrough-footer button.finish {
    background: #eb3c96;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    color: white;
    transform: scale(0, 1);
    transform-origin: center;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  body .walkthrough .walkthrough-footer button.finish:hover {
    background: white;
    color: #eb3c96;
  }
  body .walkthrough .walkthrough-footer button.finish:active {
    background: #eb3c96;
    color: white;
  }
  body .walkthrough .walkthrough-footer button.finish.active {
    transform: scale(1, 1);
    opacity: 1;
  }
  body .walkthrough .screens {
    margin: 0;
  }
  body .walkthrough .screens .media .status-badge {
    left: 136px;
    opacity: 0;
    position: absolute;
    top: 104px;
    transform: scale(0);
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-delay: 0.6s;
  }
  body .walkthrough .screens .media .status-badge i {
    display: inline;
  }
  body .walkthrough .screens .media.logo .logo {
    margin-top: 35px;
    opacity: 0;
    transform: translateY(-60px);
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 40px;
  }
  body .walkthrough .screens .media .icon {
    position: absolute;
    opacity: 0;
    transform: translateY(-30px);
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 114px;
    left: 60px;
    top: 40px;
  }
  body .walkthrough .screens .media.bars .icon {
    transform: translate(40px, 20px);
  }
  body .walkthrough .screens .media.bars .icon:nth-of-type(2) {
    transform: scale(0.25);
    transform-origin: 30% 54%;
  }
  body .walkthrough .screens .media.bars .icon:nth-of-type(3) {
    transform: scale(0.25);
    transform-origin: 30% 40%;
  }
  body .walkthrough .screens .media.bars .icon:nth-of-type(4) {
    transform: scale(0.25);
    transform-origin: 30% 26%;
  }
  body .walkthrough .screens .media.files .icon {
    transform: translate(40px, 20px);
  }
  body .walkthrough .screens .media.comm .icon {
    transform: scale(0.25);
    transform-origin: 29% 73%;
  }
  body .walkthrough .screens .media.comm .icon:nth-child(2) {
    transform-origin: 66% 85%;
  }
  body .walkthrough .screens .screen {
    opacity: 0;
    position: absolute;
    transform: translateX(-72px);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  body .walkthrough .screens .screen.active {
    opacity: 1;
    transform: translateX(0) scale(1);
    transition-delay: 0.4s;
  }
  body .walkthrough .screens .screen.active ~ .screen {
    opacity: 0;
    transform: translateX(72px);
  }
  body .walkthrough .screens .screen.active .media .status-badge {
    opacity: 1;
    transform: scale(1.75);
  }
  body .walkthrough .screens .screen.active .media.logo .logo {
    opacity: 0.8;
    transform: translateY(0);
    transition-delay: 0.6s;
  }
  body .walkthrough .screens .screen.active .media.logo .status-badge {
    transition-delay: 1s;
  }
  body .walkthrough .screens .screen.active .media.walk .icon {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.6s;
  }
  body .walkthrough .screens .screen.active .media.walk .icon:nth-child(2) {
    transition-delay: 0.725s;
  }
  body .walkthrough .screens .screen.active .media.walk .icon:nth-child(3) {
    transition-delay: 0.85s;
  }
  body .walkthrough .screens .screen.active .media.walk .status-badge {
    transition-delay: 1.4s;
  }
  body .walkthrough .screens .screen.active .media.bars .icon {
    opacity: 1;
    transform: translate(0) scale(1);
    transition-delay: 0.6s;
  }
  body .walkthrough .screens .screen.active .media.bars .icon:nth-child(2) {
    transition-delay: 1.05s;
  }
  body .walkthrough .screens .screen.active .media.bars .icon:nth-child(3) {
    transition-delay: 0.925s;
  }
  body .walkthrough .screens .screen.active .media.bars .icon:nth-child(4) {
    transition-delay: 0.8s;
  }
  body .walkthrough .screens .screen.active .media.files .icon {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.9s;
  }
  body .walkthrough .screens .screen.active .media.files .icon:nth-child(3) {
    transition-delay: 0.8s;
  }
  body .walkthrough .screens .screen.active .media.files .icon:nth-child(2) {
    transition-delay: 0.7s;
  }
  body .walkthrough .screens .screen.active .media.files .icon:nth-child(1) {
    transition-delay: 0.6s;
  }
  body .walkthrough .screens .screen.active .media.files .status-badge {
    transition-delay: 1.6s;
  }
  body .walkthrough .screens .screen.active .media.comm .icon {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.6s;
  }
  body .walkthrough .screens .screen.active .media.comm .icon:nth-child(2) {
    transition-delay: 0.8s;
  }
  body .walkthrough .screens .screen.active .media.comm .status-badge {
    transition-delay: 1.6s;
  }

.hiddenModal{
    display: none !important;
    -webkit-transition: all 0.5s ease-out;
}

.skip{
    margin: 0px auto;
    margin-top: 10px;
    display: flex;
    place-content: flex-end;
    margin-right: 5px;
    color: rgba(102, 102, 102, 0.8);
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
}

.skip:hover{
  color: black;
}