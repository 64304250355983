input#file {
    display: inline-block;
    width: 100%;
    padding: 190px 0 0 0;
    height: 175px;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: url(../../Assets/cloud-icon.png) center center no-repeat;
    outline: 2px dashed #c4c1c1;
    border-radius: 1.5rem;
    border: none;
    background-size: 60px 60px;
    color: transparent;
  }

input#file::-webkit-file-upload-button {
    display: none;
 }

 .clipboard{
  cursor: pointer;
 }